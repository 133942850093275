import * as React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import cn from 'classnames'
import MainLayout from '../layouts/MainLayout'
import PageMainSection from '../components/reusable/PageMainSection/PageMainSection'
import ButtonPrimary from '../components/common/buttons/ButtonPrimary'
import HiddenScrollItem from '../components/common/HiddenScrollItem/HiddenScrollItem'
import keyGenerator from '../utils/keyGenerator'
import IndustryItemPlate from '../components/Pages/Industry/IndustryItemPlate/IndustryItemPlate'
import * as style from '../../assets/scss/pages/portfolio.module.scss'

const Industries = ({ data, location }) => {
  const industriesPlates = data.allWpIndustry.nodes
  const seoData = data.wpPage.metaTags

  const validFiltersIndustry = []

  data.allWpPortfolioItem.edges.forEach((elem) => {
    const {
      node: { portfolioIndustries },
    } = elem

    portfolioIndustries.nodes.forEach((e) => {
      if (!validFiltersIndustry.includes(e.slug)) {
        validFiltersIndustry.push(e.slug)
      }
    })
  })

  const validateIndustry = (item) =>
    validFiltersIndustry.includes(item.industry.industry.slug)
  return (
    <MainLayout seoData={seoData} location={location}>
      <PageMainSection
        hiddenTitle={seoData.seoHiddenTitle}
        title={data.wpPage.page_industries.title}
        description={data.wpPage.page_industries.description}
        image="industries_bg.png"
        imageMobile="industries_mobile.png"
      >
        <ButtonPrimary
          buttonData={data.wpPage.page_industries.industryButton}
        />
      </PageMainSection>
      <section className={cn('container-fluid', style.plateWrapper)}>
        {industriesPlates.map(
          (item, index) =>
            item && (
              <div
                className="wrapper industry-item"
                key={keyGenerator(index, item.slug)}
              >
                <HiddenScrollItem id={item.slug} />
                <IndustryItemPlate
                  plateData={item}
                  isHaveIndustry={validateIndustry(item)}
                />
              </div>
            )
        )}
      </section>
    </MainLayout>
  )
}

export const query = graphql`
  {
    wpPage(databaseId: { eq: 44 }) {
      metaTags {
        seoTitle
        seoHiddenTitle
        seoDescription
        seoKeywords
        ogImage {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              resize(width: 1024, height: 512) {
                src
              }
            }
          }
          title
        }
      }
      page_industries {
        title
        description
        industryButton {
          addQuery
          text
          query
          useCustomUrl
          urlCustom
          url {
            ... on WpPost {
              uri
            }
            ... on WpPage {
              uri
            }
          }
        }
      }
    }
    allWpIndustry(sort: { fields: menuOrder }) {
      nodes {
        uri
        link
        slug
        industry {
          text
          title
          description
          industry {
            slug
          }
          image {
            localFile {
              url
            }
          }
        }
      }
    }
    allWpPortfolioItem(sort: { fields: menuOrder }) {
      edges {
        node {
          portfolioIndustries {
            nodes {
              name
              slug
            }
          }
          portfolioServices {
            nodes {
              name
              slug
            }
          }
          portfolioPlatforms {
            nodes {
              name
              slug
            }
          }
          portfolioTechnologies {
            nodes {
              name
              slug
            }
          }
          portfolioItem {
            type
            buttonText
            embeddedHtml
            achievements
            image {
              localFile {
                url
              }
            }
            description
            title
            caseStudySections {
              ... on WpPortfolio_item_Portfolioitem_CaseStudySections_Quote {
                name
              }
            }
          }
          slug
          uri
          link
        }
      }
    }
  }
`
Industries.propTypes = {
  data: PropTypes.shape({
    wpPage: PropTypes.shape({
      page_industries: PropTypes.shape({
        title: PropTypes.string,
        hiddenTitle: PropTypes.string,
        description: PropTypes.string,
        button: PropTypes.shape({
          text: PropTypes.string,
          query: PropTypes.string,
        }),
      }),
      metaTags: PropTypes.shape({}),
    }),
    allWpIndustry: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.object),
    }),
  }).isRequired,
  location: PropTypes.shape({}).isRequired,
}
export default Industries
