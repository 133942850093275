import React, { useState } from 'react'
import className from 'classnames'
import PropTypes, { shape } from 'prop-types'
import Fade from 'react-reveal/Fade'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import * as style from './industry-item-plate.module.scss'
import {
  FILTER_TYPE_INDUSTRY,
  FILTER_TYPE_SERVICES,
  FILTER_TYPE_TECHNOLOGY,
} from '../../../../types/types'

const IndustryItemPlate = ({
  isHaveIndustry = false,
  plateData: { industry },
}) => {
  const [descriptionState, setDescriptionState] = useState(false)

  const setDescription = () => {
    setDescriptionState(!descriptionState)
  }

  const industryUriLink = () => {
    // @TODO compress
    const query =
      (industry.industry && industry.industry.slug) ||
      (industry.service && industry.service.slug) ||
      (industry.technology && industry.technology.slug) ||
      'fintech'

    const type = industry.industry
      ? FILTER_TYPE_INDUSTRY
      : industry.service
      ? FILTER_TYPE_SERVICES
      : industry.technology
      ? FILTER_TYPE_TECHNOLOGY
      : FILTER_TYPE_INDUSTRY
    return `/portfolio/?${type}=${query}`
  }
  const imageData = () => industry.image && industry.image.localFile.url

  return (
    <div
      className={className(style.body, descriptionState && style.itemActive)}
    >
      <div
        style={{ backgroundImage: `url('${imageData()}')` }}
        className={className(style.background, style.backgroundIndustry)}
      />

      <div className={className(style.container, 'container')}>
        <div className={style.content}>
          <Fade left>
            <div className={style.titleWrapper}>
              <h2 className={style.title}>{industry.title}</h2>
            </div>
          </Fade>

          <div
            role="button"
            tabIndex={0}
            className={style.description}
            onClick={setDescription}
            onKeyDown={setDescription}
          >
            <button
              type="button"
              className={className(style.descriptionButton, style.button)}
            >
              Read more
            </button>
            <div className={style.descriptionWrap}>
              <Fade bottom>
                <div className={style.industryDescription}>
                  {industry.description}
                </div>
              </Fade>
            </div>
          </div>
          <Fade left>
            {isHaveIndustry && (
              <AnchorLink
                to={industryUriLink()}
                type="button"
                className="button-primary"
              >
                <span className="wave" />
                <span className="button-primary__content">{industry.text}</span>
              </AnchorLink>
            )}
          </Fade>
        </div>
      </div>
    </div>
  )
}

IndustryItemPlate.propTypes = {
  plateData: PropTypes.shape({
    portfolioItem: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      embeddedHtml: PropTypes.string,
      achievements: PropTypes.string,
    }),
    industry: PropTypes.shape({
      industry: shape({}),
      title: PropTypes.string,
      description: PropTypes.string,
      embeddedHtml: PropTypes.string,
      achievements: PropTypes.string,
    }),
    portfolioTechnologies: PropTypes.shape({
      node: PropTypes.arrayOf(PropTypes.any),
    }),
    uri: PropTypes.string,
    slug: PropTypes.string,
  }).isRequired,
}
export default IndustryItemPlate
