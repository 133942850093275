// extracted by mini-css-extract-plugin
export var achievements = "industry-item-plate-module--achievements--okaLR";
export var animateCircle = "industry-item-plate-module--animate-circle--nCFjL";
export var background = "industry-item-plate-module--background--+AACZ";
export var backgroundIndustry = "industry-item-plate-module--backgroundIndustry--zb7qi";
export var body = "industry-item-plate-module--body--F0AbO";
export var bounce = "industry-item-plate-module--bounce--pl2Sv";
export var button = "industry-item-plate-module--button--7hmxz";
export var container = "industry-item-plate-module--container--u8ZVB";
export var content = "industry-item-plate-module--content--fV5XW";
export var copyLink = "industry-item-plate-module--copyLink--U62le";
export var copyLinkMobile = "industry-item-plate-module--copyLinkMobile--DcyVi";
export var description = "industry-item-plate-module--description--D6045";
export var descriptionButton = "industry-item-plate-module--descriptionButton--9GdBp";
export var descriptionText = "industry-item-plate-module--descriptionText--cvJT7";
export var descriptionWrap = "industry-item-plate-module--descriptionWrap--9KHDt";
export var elem = "industry-item-plate-module--elem--DcDzU";
export var image = "industry-item-plate-module--image--f7XyG";
export var industryDescription = "industry-item-plate-module--industryDescription--ljeN7";
export var itemActive = "industry-item-plate-module--itemActive--PRdse";
export var ldsRing = "industry-item-plate-module--lds-ring--+EF2p";
export var title = "industry-item-plate-module--title--6W5CY";
export var titleWrapper = "industry-item-plate-module--titleWrapper--vBEgx";